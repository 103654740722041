import React, { useEffect, useState,useRef } from 'react';
import { toast } from 'react-toastify';

import Header from "../Header";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';
import Sidebar from '../Sidebar';
AWS.config.update({
    accessKeyId: 'AKIAWUZTVEDMASCEIVA5',
    secretAccessKey: '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',
    region: 'ap-southeast-1',
    signatureVersion: 'v4'
  });

  const s3 = new AWS.S3();


function EditBlog (){
  const navigate = useNavigate();
const { id } = useParams();
const [blog,setBlog] = useState('')
const title = useRef(null);
const description = useRef(null);
const image = useRef(null);

    // Send form data to the backend server
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`https://influ.socialapi.stageprojects.xyz/blog/getbyblog/${id}`);
          const list1 = await response.json();
          // console.log(list)
          setBlog(list1.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
    }, []);
const handleBlogSubmit = async(e) => {

    e.preventDefault();
    const params = {
        Bucket: 'clwbuckets',
        Key: `${Date.now()}.${image.current.files[0].name}`,
        Body: image.current.files[0],
        ACL: 'public-read'
      };
      const { Location } = await s3.upload(params).promise();
  // console.log(id);
 
  const response = await fetch(`https://influ.socialapi.stageprojects.xyz/blog/edit/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      title: title.current.value,
      description:description.current.value,
        image:Location,
      influencerId:'64eae2d37c84405639651156'
      // bannerimage:bannerimage,
    }),
  });
  

  if (response.ok) {
    // Assuming the server responds with a success message
    const data = await response.json();

    if (data === 'Failed To Save Blog') {
      toast.error("Failed To Save Blog");
    } else {
      toast.success("Blog Added Successfully.");
      navigate('/blog');
    }
  } else {
    // Handle server errors or invalid response
    toast.error("Failed to add blog. Please try again later.");
    console.error("Server returned an error:", response.statusText);
  }

    };
    return(
        <>
        <Sidebar />
      <div className="body-wrapper">
<Header />
            <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title fw-semibold mb-4">Edit Blog</h5>
              <div className="card">
                <div className="card-body">
                  <form action="#" onSubmit={handleBlogSubmit}>
                  <div className="mb-3">
                      <label for="title" className="form-label">Title</label>
                      <input type="text" name="title" className="form-control"
                        ref={title}
                                      defaultValue={blog.title}          
                          id="title"/>
                    </div>
                    <div className="mb-3">
                      <label for="description" className="form-label">Description</label>
                      <textarea type="textarea" row={5} col={5} name="description" className="form-control" id="description" 
                       ref={description}
                       defaultValue={blog.description}
                        aria-describedby="emailHelp">
                            </textarea>
                    </div>
                  
                    <div className="mb-3">
                      <label for="image" className="form-label">Image</label>
                      <input type="file" name="image" ref={image} className="form-control" id="image" 
                      
                        aria-describedby="emailHelp"/>
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </>
    );
}
export default EditBlog;