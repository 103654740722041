import React, { useRef, useState } from 'react';
import Sidebar from "../Sidebar";
import Header from "../Header";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AWS from 'aws-sdk';

import { awsKeyId, awsSecretKey, baseUrl } from '../../Constants/Constant';
AWS.config.update({
    accessKeyId: awsKeyId,
    secretAccessKey: awsSecretKey,
    region: 'ap-southeast-1',
    signatureVersion: 'v4'
  });

  const s3 = new AWS.S3();


function AddEmployee (){
  const data = JSON.parse(localStorage.getItem('userData'))

  const navigate = useNavigate();
 const name = useRef(null)
 const url = useRef(null)
 const type = useRef(null)
 const bannerimage = useRef(null)

const handleEmployeeSubmit = async(e) => {
  e.preventDefault();
  let imagedata 
    // e.preventDefault();
   
    if(bannerimage.current.value!=''){
      const params = {
        Bucket: 'clwbuckets',
        Key: `${Date.now()}.${bannerimage.current.files[0].name}`,
        Body: bannerimage.current.files[0],
        ACL: 'public-read'
      };
      const { Location } = await s3.upload(params).promise();
      imagedata = Location
      // console.log('if')
    }
    else{
        imagedata = 'test.png'
        // console.log(imagedata,'dsfdgfgfbbgbgbgbgbggbgbg')
    }
  // alert(title.current.value)
  let projectdata ={
      "name":name.current.value,
      "url":url.current.value,
      "type":type.current.value,
      "bannerimage":imagedata,
      "influencerId":data._id
    }
   
   
      axios.post(`${baseUrl}/project/register`,projectdata)
      .then((response) => {
      //   console.log(response.data,'shgsgggfj')
      toast.success('Influencer Added Successfully')
      navigate('/project')
       
        // setData(response.data);
      })
      .catch((error) => {
      //   console.log(error)
        toast.error(error);
      });

    };
    return(
        <>
        <Sidebar />
      <div className="body-wrapper">
<Header />
            <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title fw-semibold mb-4">Forms</h5>
              <div className="card">
                <div className="card-body">
                    <div className="mb-3">
                      <label for="exampleInputPassword1" className="form-label">Name</label>
                      <input type="name" name="name" className="form-control"
                      ref={name}
                                                
                          id="exampleInputPassword1"/>
                    </div>
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">Url</label>
                      <input type="text" name="address" className="form-control" id="exampleInputEmail1"
                       ref={url}
                        aria-describedby="emailHelp"/>
                    </div>
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">Type</label>
                      <input type="text" name="address" className="form-control" id="exampleInputEmail1"
                       ref={type}
                        aria-describedby="emailHelp"/>
                    </div>
                    <div className="mb-3">
                      <label for="image" className="form-label">Banner Image</label>
                      <input type="file" name="image" className="form-control" id="image"
                       ref={bannerimage}
                        aria-describedby="emailHelp"/>
                    </div>
                    <button onClick={handleEmployeeSubmit} className="btn btn-primary">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </>
    );
}
export default AddEmployee;