import React, { useRef, useState } from 'react';
import Sidebar from "../Sidebar";
import Header from "../Header";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';
AWS.config.update({
    accessKeyId: 'AKIAWUZTVEDMASCEIVA5',
    secretAccessKey: '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',
    region: 'ap-southeast-1',
    signatureVersion: 'v4'
  });
  const s3 = new AWS.S3();

function AddBlog (){
  const data = JSON.parse(localStorage.getItem('userData'))

  const navigate = useNavigate();
const title = useRef(null);
const description = useRef(null);
const image = useRef(null);

const handleSubmit = async (e) => {
    e.preventDefault();
  console.log(image.current.files[0].name,'ddddd')
  const params = {
    Bucket: 'clwbuckets',
    Key: `${Date.now()}.${image.current.files[0].name}`,
    Body: image.current.files[0],
    ACL: 'public-read'
  };
  const { Location } = await s3.upload(params).promise();
    // alert(title.current.value)
    const response = await fetch("https://influ.socialapi.stageprojects.xyz/blog/add", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                title: title.current.value,
                description:description.current.value,
image:Location,
                influencerId:data._id
                // bannerimage:bannerimage,
              }),
            });
          
            if (response.ok) {
              // Assuming the server responds with a success message
              const data = await response.json();
          
              if (data === 'Failed To Save Blog') {
                toast.error("Failed To Save Blog");
              } else {
                toast.success("Blog Added Successfully.");
                navigate('/blog');
              }
            } else {
              // Handle server errors or invalid response
              toast.error("Failed to add blog. Please try again later.");
              console.error("Server returned an error:", response.statusText);
            }
          } 
  
   
//   try {
//     let data ={
//       url:url,
//         name:url,
//         type:name,
//         bannerimage:bannerimage
//     }
//     // Send form data to the backend server
//     const response = await fetch("https://influ.socialapi.stageprojects.xyz/project/register", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         influencerId: "64eae2d37c84405639651156",
//         url:url,
//         name:url,
//         type:name,
//         bannerimage:bannerimage,
//       }),
//     });
  
//     if (response.ok) {
//       // Assuming the server responds with a success message
//       const data = await response.json();
  
//       if (data === 'Failed To Save Employee') {
//         toast.error("Failed To Save Employee");
//       } else {
//         toast.success("Employee Registered Successfully.");
//         navigate('/employee');
//       }
//     } else {
//       // Handle server errors or invalid response
//       toast.error("Failed to register employee. Please try again later.");
//       console.error("Server returned an error:", response.statusText);
//     }
//   } catch (error) {
//     // Handle network or other unexpected errors
//     console.error("An error occurred:", error);
//   }
  

//     };
    return(
        <>
        <Sidebar />
      <div className="body-wrapper">
<Header />
            <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title fw-semibold mb-4">Add New Blog</h5>
              <div className="card">
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label for="title" className="form-label">Title</label>
                      <input type="text" name="title" className="form-control"
                        ref={title}
                                                
                          id="title"/>
                    </div>
                    <div className="mb-3">
                      <label for="description" className="form-label">Description</label>
                      <input type="text" name="description" className="form-control" id="description" 
                       ref={description}
                        aria-describedby="emailHelp"/>
                    </div>
                  
                    <div className="mb-3">
                      <label for="image" className="form-label">Image</label>
                      <input type="file" name="image" ref={image} className="form-control" id="image" 
                      
                        aria-describedby="emailHelp"/>
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </>
    );
}
export default AddBlog;